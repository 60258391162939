@import "../../scss/main";

.textInput {
  display: flex;
  flex-direction: column;
  width: 100%;


  label {
    font-size: 14px;
    font-weight: 500;
    color: $TextColor;
  }

  input {
    height: 44px;
    border: none;
    outline: none;
    border-bottom: 1px solid $HeadingColor;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    background-color: transparent;
    font-size: 14px;
    color: $TextColor;

    &::placeholder {
      color: $MutedTextColor;
    }
  }
}
