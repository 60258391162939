@import "../../scss/main";

.idBox {
  position: absolute;
  top: -80px;
}

.partySec {
  position: relative;
  padding: 4rem 0;
  background-color: rgba(255, 226, 0, 0.5);

  @include md {
    padding: 2rem 0;
  }

  .partySec_idBox {
    position: absolute;
    top: -80px;
  }

  .partySec_circle1 {
    position: absolute;
    bottom: 10%;
    left: 0;
    width: 20vw;
  }

  .partySec_circle2 {
    position: absolute;
    top: 5%;
    left: 0;
    width: 13vw;
  }

  .container {
    width: 1216px;
    max-width: calc(100vw - 2rem);
    margin: 0 auto;
    position: relative;
  }

  .partySec_header {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 4rem;
    color: $TextColor;
    margin-bottom: 2rem;

    @include lg {
      gap: 2rem;
      margin-bottom: 0.5rem;
    }

    @include md {
      grid-template-columns: 1fr;
    }
  }

  .partySec_headerContent {
    @include md {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    small {
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 1.4;
      letter-spacing: 1px;
      text-transform: uppercase;
      margin-bottom: 0.5rem;
    }

    h3 {
      color: $HeadingColor;
      font-size: 44px;
      font-weight: 700;
      line-height: 1.1;
      letter-spacing: -0.88px;
      margin-bottom: 2rem;

      @include lg {
        font-size: 32px;
      }

      @include md {
        margin-bottom: 0.2rem;
        text-align: center;
      }
    }

    p {
      font-size: 16px;
      line-height: 1.6;
      margin: 0;
      margin-bottom: 1rem;

      @include md {
        text-align: center;
        font-size: 14px;
        margin-bottom: 0.5rem;
      }
    }

    .partySec_btns {
      display: flex;
      gap: 1rem;
      margin-top: 2rem;

      @include md {
        margin-top: 1.5rem;
      }
    }
  }

  .partySec_imageContainerPicture {
    border: 1rem solid $WhiteColor;
    transform: rotate(3.651deg);
    max-height: 100%;
    max-width: 100%;
    width: 100%;
    height: 376px;
    object-fit: cover;

    @include md {
      border: 7px solid $WhiteColor;
    }
  }

  .partySec_heading {
    position: relative;
    padding: 0.3rem 0.55rem;
    width: fit-content;

    span {
      position: relative;
      color: $PartyText;
      font-family: "Creattion";
      font-size: 50px;
      line-height: 0.9;
      letter-spacing: -0.507px;
      z-index: 2;

      @include xxl {
        font-size: 45px;
      }

      @include xl {
        font-size: 40px;
      }
      @include lg {
        font-size: 30px;
      }

      @include sm {
        font-size: 21px;
      }
    }

    .partySec_headingWhite {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      object-fit: cover;
      object-position: bottom;
    }

    .partySec_headingGrey {
      position: absolute;
      top: 0;
      left: 0;
      height: 95%;
      width: 100%;
      object-fit: cover;
      object-position: bottom;
    }
  }

  .partySec_main {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    margin-top: -1.75rem;

    @include lg {
      margin-top: 1rem;
    }

    @include md {
      margin-top: 0;
      row-gap: 0.5rem;
    }
  }

  .partySec_imageContainer1 {
    position: relative;

    @include md {
      width: 80%;
      margin: 0 auto;
      margin-left: 15%;
    }

    @include sm {
      width: 90%;
      margin: 0 auto;
      margin-left: 10%;
      margin-bottom: -1rem;
    }

    .partySec_heading {
      transform: translate(-3rem, -4.25rem);

      @include sm {
        transform: translate(-2rem, -4.25rem);
      }
    }

    .partySec_imageContainer1Arrow {
      position: absolute;
      bottom: -4px;
      right: 0;
      z-index: 2;
      width: 146px;

      @include lg {
        width: 90px;
        transform: translate(-100%, 0);
      }

      @include md {
        width: 30%;
        transform: translate(0, 10%);
        right: 15%;
      }
    }

    .partySec_imageContainerPicture {
      @include md {
        height: auto;
        border: 10px solid $WhiteColor;
        width: calc(100% - 3rem);
        max-height: 50vh;
      }

      @include sm {
        height: auto;
        min-height: 200px;
        border: 10px solid $WhiteColor;
        width: calc(100% - 1rem);
        max-height: 50vh;
      }

      @include xs {
        @include sm {
          height: 200px;
          min-height: auto;
          border: 10px solid $WhiteColor;
          width: calc(100% - 1rem);
          max-height: 50vh;
        }
      }
    }
  }

  .partySec_imageContainer2 {
    position: relative;
    width: 22%;
    height: fit-content;

    @include md {
      width: 37%;
    }

    .partySec_imageContainerPicture {
      border-top: 46px solid;
      border-right: 16px solid;
      border-bottom: 80px solid;
      border-left: 16px solid;
      border-color: $WhiteColor;
      height: 387px;
      transform: none;

      @include lg {
        height: calc(387px * 0.8);
        border-top: calc(46px * 0.8) solid $WhiteColor;
        border-bottom: calc(80px * 0.8) solid $WhiteColor;
      }

      @include md {
        height: 198px;
        border-top: 20px solid $WhiteColor;
        border-bottom: 34px solid $WhiteColor;
        border-left: 7px solid $WhiteColor;
        border-right: 7px solid $WhiteColor;
      }
    }

    .partySec_heading {
      width: max-content;
      max-width: calc(100% - 1rem);
      margin-left: 50%;
      transform: translate(-50%, -5.5rem);
      text-align: center;

      @include md {
        transform: translate(-50%, 0);
        max-width: calc(100% - 0.25rem);
        margin-top: -44px;
      }
    }

    .partySec_imageContainer2Arrow {
      position: absolute;
      top: -4px;
      right: 0;
      z-index: 2;
      width: 146px;
      transform: translate(65%, -15%);

      @include lg {
        width: calc(146px * 0.8);
      }

      @include md {
        width: 60%;
      }
    }
  }

  .partySec_imageContainer3 {
    position: relative;
    width: 40%;
    margin-left: -3%;

    @include md {
      width: 60%;
    }

    .partySec_imageContainerPicture {
      height: 321px;
      transform: none;

      @include lg {
        height: calc(321px * 0.8);
      }

      @include md {
        height: auto;
        border: 7px solid $WhiteColor;
      }
    }

    .partySec_heading {
      width: max-content;
      margin-left: auto;
      max-width: calc(100% - 2rem);
      transform: translate(2rem, -70%);
      padding: 0.8rem 0.5rem;

      @include md {
        margin-right: 0.5rem;
        transform: translate(0, 0);
        margin-top: -3rem;
      }

      .partySec_headingGrey {
        top: 50%;
        transform: translateY(-50%);
        height: 92%;
        object-position: center;
      }

      .partySec_headingWhite {
        height: 100%;
        object-position: center;
      }
    }

    .partySec_imageContainer3Stars {
      position: absolute;
      bottom: 7rem;
      right: 0;
      z-index: 2;
      width: 84px;
      transform: translate(50%, -15%);

      @include lg {
        width: calc(84px * 0.8);
      }

      @include md {
        width: 21%;
        bottom: 0;
        right: 1rem;
        transform: translate(0, 100%);
      }
    }
  }

  .partySec_imageContainer4 {
    position: relative;
    width: 27%;

    @include md {
      width: 55%;
    }

    .partySec_imageContainerPicture {
      border-top: 46px solid;
      border-right: 16px solid;
      border-bottom: 80px solid;
      border-left: 16px solid;
      border-color: $WhiteColor;
      transform: none;
      height: 524px;

      @include lg {
        height: calc(524px * 0.8);
        border-top: calc(46px * 0.8) solid $WhiteColor;
        border-bottom: calc(80px * 0.8) solid $WhiteColor;
      }

      @include md {
        border: 7px solid $WhiteColor;
        min-height: 140px;
        height: auto;
        width: 100%;
      }
    }

    .partySec_heading {
      width: max-content;
      margin-left: auto;
      transform: translate(0, -50%) rotate(-2deg);
      padding: 0.8rem 0.5rem;
      margin-top: -5.5rem;
      max-width: calc(100% - 1rem);
      text-align: center;

      @include md {
        margin-left: 50%;
        transform: translate(-50%, 0);
        margin-top: -2rem;
        padding: 0.4rem 0.3rem;
      }

      .partySec_headingGrey {
        top: 50%;
        transform: translateY(-50%);
        height: 98%;
        object-position: center;
      }

      .partySec_headingWhite {
        height: 100%;
        object-position: center;
      }
    }

    .partySec_imageContainer2Arrow {
      position: absolute;
      top: -4px;
      right: 0;
      z-index: 2;
      width: 146px;
      transform: translate(65%, -15%);
    }

    .partySec_imageContainer4Stars {
      display: none;

      @include md {
        display: inline-block;
        position: absolute;
        top: 0;
        left: 2px;
        z-index: 2;
        width: 23%;
        transform: translateY(-50%);
      }
    }
  }

  .partySec_imageContainer5 {
    position: relative;
    width: 36.5%;

    @include md {
      width: 43%;
    }

    .partySec_imageContainerPicture {
      transform: none;
      height: 325px;

      @include lg {
        height: calc(325px * 0.8);
      }

      @include md {
        min-height: 228px;
        height: auto;
        border: 7px solid $WhiteColor;
        border-top: 20px solid $WhiteColor;
        border-bottom: 35px solid $WhiteColor;
      }
    }

    .partySec_heading {
      width: max-content;
      margin-left: auto;
      max-width: calc(100% - 1rem);
      margin-left: 50%;
      transform: translate(-50%, -65%);
      padding-left: 0.5rem;
      padding-right: 1.25rem;

      @include md {
        margin-top: -54px;
        transform: translate(0, 0);
        margin-left: auto;
        margin-right: 0.25rem;
        padding-right: 0.5rem;
      }
    }

    .partySec_imageContainer5Stars {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
      width: 85px;
      transform: translateY(-60%);

      @include lg {
        width: calc(85px * 0.8);
      }

      @include md {
        display: none;
      }
    }

    .partySec_imageContainer5Arrow {
      position: absolute;
      bottom: 0;
      right: 0;
      z-index: 2;
      width: 138px;
      transform: translate(35%, -75%);

      @include lg {
        width: calc(138px * 0.8);
      }

      @include md {
        display: none;
      }
    }

    .partySec_imageContainer2Arrow {
      position: absolute;
      top: -4px;
      right: 0;
      z-index: 2;
      width: 146px;
      transform: translate(65%, -15%);

      @include lg {
        width: calc(146px * 0.8);
      }
    }
  }
  .partySec_imageContainer6 {
    position: relative;
    width: 28%;
    margin-left: -0.5rem;

    @include md {
      margin-left: 0;
      width: 43%;
    }

    .partySec_imageContainerPicture {
      border-top: 46px solid $WhiteColor;
      border-bottom: 80px solid $WhiteColor;
      border-color: $WhiteColor;
      transform: none;
      height: 531px;

      @include lg {
        height: calc(531px * 0.8);
        border-top: calc(46px * 0.8) solid $WhiteColor;
        border-bottom: calc(80px * 0.8) solid $WhiteColor;
        border-color: $WhiteColor;
      }

      @include md {
        border-top: 20px solid $WhiteColor;
        border-bottom: 34px solid $WhiteColor;
        min-height: 229px;
        height: auto;
        border-color: $WhiteColor;
      }
    }

    .partySec_heading {
      width: max-content;
      margin-left: 50%;
      max-width: calc(100% - 2rem);
      transform: translate(-50%, 0);
      padding: 1.35rem 0.5rem;
      margin-top: -110px;

      @include md {
        margin-top: -50px;
        max-width: calc(100% - 0.5rem);
        padding: 0.5rem 0.2rem;
      }

      .partySec_headingGrey {
        top: 50%;
        transform: translateY(-50%);
        height: 92%;
        object-position: center;
      }

      .partySec_headingWhite {
        height: 100%;
        object-position: center;
      }
    }

    .partySec_imageContainer6Arrow {
      display: none;

      @include md {
        display: inline-block;
        position: absolute;
        top: 48%;
        right: 0;
        width: 49%;
        z-index: 2;
        transform: translate(70%, 0);
      }
    }
  }
  .partySec_imageContainer7 {
    position: relative;
    width: 33%;
    margin-top: 3%;

    @include md {
      width: 52%;
      margin-top: 0;
    }

    .partySec_imageContainerPicture {
      transform: none;
      height: 325px;

      @include lg {
        height: calc(325px * 0.8);
      }

      @include md {
        min-height: 126px;
        height: auto;
      }
    }

    .partySec_heading {
      width: max-content;
      margin-left: 50%;
      transform: translate(-50%, 0);
      padding: 0.8rem 0.5rem;
      margin-top: -17%;
      max-width: 100%;
      text-align: center;

      @include md {
        padding: 0.3rem 0.2rem;
        transform: translate(-50%, -90%);
        margin-top: 0;
      }

      .partySec_headingGrey {
        top: 50%;
        transform: translateY(-50%);
        height: 92%;
        object-position: center;
      }

      .partySec_headingWhite {
        height: 100%;
        object-position: center;
      }
    }

    .partySec_imageContainer7Stars {
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 2;
      height: 126px;
      transform: translate(-20%, 50%);

      @include lg {
        height: calc(126px * 0.8);
      }

      @include md {
        height: auto;
        width: 41%;
        top: 0;
        right: 0;
        left: unset;
        transform: translate(-4px, -50%);
      }
    }

    .partySec_imageContainer7Arrow {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
      width: 250px;
      transform: translate(-20%, -70%);

      @include lg {
        width: calc(250px * 0.8);
      }

      @include md {
        width: 70%;
        transform: translate(-47%, -70%);
      }
    }
  }
}
