@import "../../scss/main";

.home {
  position: relative;
  background: linear-gradient(
      0deg,
      rgba(70, 134, 255, 0.1) 0%,
      rgba(70, 134, 255, 0.1) 100%
    ),
    #fff;
  min-height: calc(var(--vh, 1vh) * 100);
}

.home_menuButton {
  position: fixed;
  bottom: 2rem;
  right: 3rem;
  z-index: 50;
  cursor: pointer;
  border-radius: 50%;

  @include md {
    bottom: 1rem;
    right: 1.5rem;
  }

  img {
    height: 4rem;
  border-radius: 50%;
  box-shadow: 0px 0px 0px 7px rgba(0, 0, 0, 0.08);



    @include md {
      height: 3rem;
    }
  }
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  select,
  textarea,
  input {
    font-size: 16px !important;
  }
  input:focus,
  select:focus,
  textarea:focus {
    font-size: 16px;
  }
}
