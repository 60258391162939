@import "../../scss/main";

.servicesCarousel {
  width: 1216px;
  max-width: calc(100vw - 10rem);
  margin: 0 auto;
  transform: translateY(-50%);

  @include md {
    max-width: calc(100vw - 2rem);
  }

  .servicesCarousel_image {
    height: 305px;
    border-radius: 10px;
    background-color: #667070;

    @include md {
      height: 209px;
    }
  }

  .servicesCarousel_item {
    padding-right: 1rem;
    cursor: pointer;

    @include md {
      padding-right: 0.5rem;
    }
  }
  .slick-slide,
  .slick-slide div {
    outline: none !important;
    border: none;
  }
  .slick-prev {
    left: -1rem;
    transform: translate(-100%, -50%);
  }

  .slick-next {
    right: -1rem;
    transform: translate(100%, -50%);
  }

  .slick-arrow {
    height: 2.25rem;
    width: 2.25rem;
    border-radius: 50%;
    background-color: rgba($color: $WhiteColor, $alpha: 0.8);
    display: inline-flex !important;
    justify-content: center;
    align-items: center;

    @include md {
      display: none !important;
    }

    &::before {
      content: "";
    }

    &:hover {
      background-color: rgba($color: $WhiteColor, $alpha: 1);
    }

    svg {
      path {
        stroke: #f06575;
      }
    }
  }

  .slick-disabled {
    cursor: not-allowed;

    &:hover {
      background-color: rgba($color: $WhiteColor, $alpha: 0.8);
    }

    svg {
      path {
        stroke: #667070;
      }
    }
  }
}
