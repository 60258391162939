@import "../../scss/main";

.contactSec {
  padding: 4rem 0;
  position: relative;
  z-index: 2;
  @include md {
    padding: 2rem 0;
  }

  .container {
    width: 1215px;
    max-width: calc(100vw - 2rem);
    margin: 0 auto;
    display: flex;
    gap: 10%;

    @include md {
      gap: 1.5rem;
      flex-direction: column;
    }
  }

  .contactSec_content {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    width: 50%;

    @include md {
      width: 100%;
      gap: 1rem;
    }

    h3 {
      font-size: 2rem;
      line-height: 1.2;
      color: $HeadingColor;
    }

    p {
      font-size: 1rem;
      line-height: 1.6;
      color: $TextColor;

      strong {
        font-weight: 500;
        display: block;
        margin-top: 0.5rem;
      }

      small {
        font-size: 12px;
        font-weight: 600;
        text-transform: uppercase;
        display: block;
        margin-bottom: 2px;
      }

      span {
        display: block;
      }

      a {
        color: $PinkColor;
        text-decoration: none;
      }
    }
  }

  .contactSec_form {
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 32px;

    @include md {
      width: 100%;
      gap: 22px;
    }

    select {
      background-color: transparent;
    }

    .contactSec_formInputs {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      row-gap: 32px;
      column-gap: 22px;

      @include lg {
        grid-template-columns: 1fr;
      }
    }

    button {
      width: fit-content;
      margin-left: auto;

      @include sm {
        width: 100%;
      }
    }
  }
}
