@import "../../scss/main";

.summerCamp {
  background: linear-gradient(
      0deg,
      rgba(70, 134, 255, 0.1) 0%,
      rgba(70, 134, 255, 0.1) 100%
    ),
    #fff;
  min-height: calc(var(--vh, 1vh) * 100);

  .container {
    width: 1215px;
    max-width: 90vw;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
  }

  .summerCamp_logo {
    height: 80px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    img {
      height: 1.7rem;
      cursor: pointer;
    }

    svg {
      position: absolute;
      left: 0;
      height: 1.7rem;
      cursor: pointer;
      fill: $HeadingColor;
      stroke: $HeadingColor;
    }
  }

  .summerCamp_heading {
    position: relative;
    margin-bottom: 30px;
    text-align: center;

    @include md {
      width: 100%;
      margin-bottom: 10px;
    }

    h3 {
      font-size: 44px;
      font-weight: 700;
      color: $HeadingColor;
      line-height: 1.1;

      @include md {
        font-size: 32px;
      }
    }
  }

  .summerCamp_topText {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-bottom: 2rem;

    p {
      font-size: 1rem;
      color: $TextColor;
      font-style: italic;

      @include md {
        font-size: 14px;
      }
    }
  }

  .summerCamp_paras {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    margin-bottom: 2rem;

    h4 {
      font-size: 1.125rem;
      color: $HeadingColor;
      text-decoration: underline;
      margin-bottom: 0.5rem;

      @include md {
        font-size: 1rem;
      }

      svg {
        margin-top: 2px;
        flex-shrink: 0;
        transform: translateY(2.5px);
        margin-right: 0.5rem;
        height: 1.125rem;

        @include md {
          height: 1rem;
        }
      }
    }

    .summerCamp_paras_noUnderline {
      text-decoration: none;
    }

    h3 {
      font-size: 2rem;
      color: $HeadingColor;
      margin-bottom: 0.5rem;

      @include md {
        font-size: 1.5rem;
      }
    }

    a {
      font-weight: 500;
      color: $PinkColor;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }

    p {
      font-size: 1rem;
      color: $TextColor;
      margin: 0.5rem 0;

      &:last-child {
        font-style: italic;
      }

      @include md {
        font-size: 14px;
      }

      b {
        font-weight: 600;
      }

      svg {
        margin-top: 2px;
        flex-shrink: 0;
        transform: translateY(2.5px);
        margin-right: 0.25rem;
      }
    }
    ul {
      margin-left: 1rem;
    }

    .summerCamp_paras_squareList {
      list-style: none;
      padding-left: 0;
      margin-left: 0;

      li {
        align-items: center;
        flex-wrap: wrap;
      }

      li svg {
        margin-top: 2px;
        flex-shrink: 0;
        transform: translateY(2.5px);
        margin-right: 0.25rem;
      }

      li b {
        font-weight: 600;
      }
    }
  }

  .summerCamp_link {
    text-decoration: none;
    margin: 1.5rem auto;
  }
}
