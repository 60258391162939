@import "../../scss/main";

.membershipSec {
  background-color: $MustardColor;
  padding: 4rem 0;
  position: relative;

  .membershipSec_images {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;

    .membershipSec_white {
      position: absolute;
      width: 24vw;
      top: 0;
      left: 0;
      min-width: 150px;

      @include sm {
        min-width: 50vw;
        transform: translateX(-40%);
      }
    }

    .membershipSec_pink {
      position: absolute;
      width: 15.5vw;
      top: 2.5rem;
      left: 5vw;
      min-width: 100px;

      @include sm {
        left: 0;
        transform: translateX(-40%);
      }
    }

    .membershipSec_circles {
      position: absolute;
      width: 19.5vw;
      top: 0;
      left: 40%;
      min-width: 150px;

      @include sm {
        top: 25%;
        left: none;
        right: 2%;
      }
    }

    .membershipSec_dots {
      position: absolute;
      width: 12.5vw;
      bottom: 1rem;
      left: 0;
      min-width: 100px;

      @include md {
        bottom: none;
        top: 20%;
      }
    }

    .membershipSec_yellow {
      position: absolute;
      width: 13.5vw;
      right: 0;
      top: 4%;
      min-width: 100px;

      @include md {
        top: unset;
        bottom: 1rem;
      }
    }

    .membershipSec_path {
      position: absolute;
      width: 22vw;
      right: 5vw;
      top: 0;
      min-width: 100px;

      @include md {
        display: none;
      }
    }
  }

  .container {
    position: relative;
    width: 1215px;
    max-width: calc(100vw - 2rem);
    margin: 0 auto;
    display: grid;
    // grid-template-columns: repeat(2, 1fr);
    gap: 4rem;
    align-items: center;

    @include xl {
      gap: 2rem;
      grid-template-columns: 1fr;
    }
  }

  .membershipSec_content {
    @include md {
      text-align: center;
    }
    small {
      font-size: 14px;
      font-weight: 600;
      text-transform: uppercase;
      margin-bottom: 2px;
      color: $TextColor;
    }

    h3 {
      font-size: 44px;
      font-weight: 700;
      color: $HeadingColor;
      line-height: 1.1;
      margin-bottom: 1rem;

      @include md {
        font-size: 32px;
        margin-bottom: 0.5rem;
      }
    }

    p {
      font-size: 1rem;
      color: $TextColor;
      line-height: 1.6;

      &:not(:last-child) {
        margin-bottom: 1rem;

        @include md {
          margin-bottom: 0.5rem;
        }
      }

      @include md {
        font-size: 14px;
      }
    }
  }

  .membershipSec_contentButton {
    border: 1px solid $PinkColor;
    margin-top: 1.5rem !important;
  }

  .membershipSec_cards {
    display: flex;
    gap: 2rem;

    @include xl {
      width: 632px;
      max-width: calc(100vw - 2rem);
      margin: 0 auto;
    }

    @include md {
      gap: 1rem;
    }

    @include sm {
      display: none;
    }
  }

  .membershipCardMonthly {
    .membershipCard_headerMain {
      background-color: $BlueColor !important;
    }
  }

  .membershipCard {
    width: 50%;
    max-width: 80vw;

    @include sm {
      width: calc(100% - 8px);
    }

    &:last-child {
      margin-top: 4rem;

      @include xl {
        margin-top: 2rem;
      }

      @include sm {
        margin-top: 0;
      }
    }

    .membershipCard_button {
      border: 1px solid $PinkColor;
    }

    .membershipCard_header {
      padding: 20px;
      border-radius: 20px;
      background-color: $WhiteColor;
    }

    .membershipCard_headerMain {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      padding: 1.5rem 0;
      background-color: $YellowColor;

      h3 {
        text-align: center;
        font-feature-settings: "clig" off, "liga" off;
        font-family: Poppins;
        font-size: 52px;
        font-weight: 700;
        line-height: 1;
        color: $HeadingColor;
      }

      h4 {
        text-align: center;
        font-feature-settings: "clig" off, "liga" off;
        font-family: Poppins;
        font-size: 20px;
        font-weight: 700;
        line-height: 1.3;
        color: $TextColor2;
      }
    }

    .membershipCard_titleWrapper {
      display: flex;
      align-items: baseline;
      margin-bottom: 10px;
    }
    .membershipCard_titleSubTitle {
      font-size: 15px;
      font-weight: 500;
    }
    .membershipCard_features {
      padding: 2rem;
      border-radius: 20px;
      background: $WhiteColor;
      display: flex;
      flex-direction: column;
      gap: 1rem;
      align-items: center;
      margin-top: -1px;

      button {
        margin-top: 1rem;
        width: 100%;
      }
    }
  }

  .mobileMembershipCarousel {
    display: none;
    width: calc(100vw - 2rem);
    margin: 0 auto;

    @include sm {
      display: block;
    }

    .slider {
      display: none;
      width: 100%;
      @include sm {
        display: block;
      }
    }
    .slick-slide,
    .slick-slide div {
      outline: none !important;
      border: none;
    }

    .slick-track {
      display: flex !important;
    }
  }
}
