$WhiteColor: #fff;
$BlackColor: #000;
$TextColor: #334041;
$HeadingColor: #001011;
$PinkColor: #f06575;
$BlueColor: #78bee1;
$DarkBlueColor: #4686ff;
$TextColor2: #333;
$FadeTextColor: #667070;
$DarkBlueColor2: #264DA0;
$MutedTextColor: #999FA0;
$GreyColor: #CCCFCF;
$SecondaryTextColor:#404040;
$MustardColor: #E5B422;
$YellowColor: #FFE200;
$BlueBG: #ecf3ff;
$PartyText: #323635;

$PrimaryColor: #3d3e90;
$PurpleColor: #8c87ff;
$RedColor: #ba000d;


// media queries

// extra small
$screen-xs-min: 425px;

$screen-sm-min: 576px;

// Small tablets (portrait view)
$screen-md-min: 767px;

// Tablets and small desktops
$screen-lg-min: 992px;

// Large tablets and desktops
$screen-xl-min: 1200px;

// extra Large tablets and desktops
$screen-xxl-min: 1400px;

@mixin xs {
  @media (max-width: #{$screen-xs-min}) {
    @content;
  }
}

@mixin sm {
  @media (max-width: #{$screen-sm-min}) {
    @content;
  }
}

// Medium devices
@mixin md {
  @media (max-width: #{$screen-md-min}) {
    @content;
  }
}

// Large devices
@mixin lg {
  @media (max-width: #{$screen-lg-min}) {
    @content;
  }
}

// Extra large devices
@mixin xl {
  @media (max-width: #{$screen-xl-min}) {
    @content;
  }
}
//Extra Extra large devices
@mixin xxl {
  @media (max-width: #{$screen-xxl-min}) {
    @content;
  }
}
