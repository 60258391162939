@import "../../scss/main";

.dropdown {
  display: flex;
  flex-direction: column;

  label {
    font-size: 14px;
    color: $TextColor;
  }

  select {
    height: 2.5rem;
    width: 100%;
    border: none;
    outline: none;
    border-bottom: 1px solid $HeadingColor;

    &:invalid {
      color: $MutedTextColor;
    }
  }
}
