@import "../../scss/main";

.navbar_active {
  @include lg {
    background: $BlueColor !important;
    border-bottom: 1px solid $BlueColor;
  }
}

.navbarFixed {
  background: linear-gradient(
      0deg,
      rgba(70, 134, 255, 0.1) 0%,
      rgba(70, 134, 255, 0.1) 100%
    ),
    #fff;
  z-index: 10;
}

.navbar {
  height: 80px;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 5;
  width: 100vw;
  max-width: 100vw;
  position: fixed;
  top: 0;
  left: 0;

  .container {
    width: 1215px;
    max-width: 90vw;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 0.5rem;
    z-index: 10;
    position: relative;

    @include md {
      max-width: 100vw;
      padding: 0 1rem;
    }
  }

  .navbar_logo {
    height: 1.7rem;
    cursor: pointer;

    @include md {
      height: 1.5rem;
    }
  }

  .navbar_calendarButton {
    display: none;
    height: 2rem;
    width: 2rem;
    border-radius: 50%;
    background-color: $PinkColor;
    justify-content: center;
    align-items: center;
    position: relative;
    cursor: pointer;

    @include lg {
      display: inline-flex;
    }

    .navbar_calendarButtonImg1 {
      height: 1.5rem;
    }

    .navbar_calendarButtonImg2 {
      position: absolute;
      bottom: 5.5px;
      height: 8px;
    }
  }

  .navbar_items {
    display: flex;
    gap: 1.5rem;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;

    @include xl {
      gap: 1rem;
      row-gap: 0.5rem;
    }

    @include lg {
      display: none;
    }
  }

  .navbar_button {
    @include lg {
      display: none;
    }
  }

  .navItem_active {
    color: $TextColor !important;
    text-decoration: underline;
    font-weight: bold;
    span {
      color: $TextColor !important;

      @include md {
        color: $TextColor !important;
        text-decoration: underline;
      }
    }
  }

  .navItem {
    position: relative;
    display: flex;
    gap: 0.3rem;
    cursor: pointer;
    color: $TextColor;

    @include lg {
      flex-direction: column;
    }

    &:hover {
      span,
      a {
        text-decoration: underline;
      }
    }

    span,
    a {
      color: $TextColor;
      text-decoration: none;
      font-weight: 500;

      @include md {
        color: $TextColor;
      }
    }

    &:hover .navItem_menu {
      display: flex;
    }

    .navItem_menu {
      position: absolute;
      bottom: 0;
      left: 50%;
      background-color: $WhiteColor;
      box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.75);
      padding: 0.5rem 2rem;
      transform: translate(-50%, 100%);
      border-radius: 0.3rem;
      cursor: default;
      display: none;
      flex-direction: column;
      transition: all 0.2s;
      align-items: center;

      @include lg {
        position: relative;
        box-shadow: none;
        display: block;
        transform: none;
        bottom: auto;
        left: auto;
      }

      div {
        white-space: nowrap;
        cursor: pointer;
        font-size: 1.125rem;
        color: $BlackColor;

        &:hover {
          color: $PrimaryColor;
        }
      }
    }
  }

  .navbar_mobileMenu {
    display: none;
    position: absolute;
    bottom: 0;
    left: 0;
    transform: translateY(100%);
    width: 100%;
    transition: all 0.3s ease-in-out;
    background-color: $WhiteColor;
    padding-bottom: 1rem;
    z-index: 5;
    min-height: calc(var(--vh, 1vh) * 100 - 80px);
    background-color: $BlueColor;
    flex-direction: column;
    justify-content: center;
    padding-left: 1.75rem;
    padding-bottom: calc(var(--vh, 1vh) * 5);

    @include lg {
      display: flex;

      .navbar_items {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: calc(var(--vh, 1vh) * 5);
      }

      .navItem {
        font-size: 1.125rem;
        span {
          font-weight: 600;
        }
      }
      .navbar_login {
        display: flex;
        margin-top: 1rem;
        padding-left: 1.5rem;
      }
    }
  }

  .hamburger {
    display: none;

    @include lg {
      display: block;
    }
  }

  .hamburger .line {
    width: 24px;
    height: 3px;
    background-color: $BlackColor;
    display: block;
    margin: 5px auto;
    -webkit-transition: all 0.1s ease-in-out;
    -o-transition: all 0.1s ease-in-out;
    transition: all 0.1s ease-in-out;
    border-radius: 0.5rem;
  }

  .hamburger:hover {
    cursor: pointer;
  }

  .hamburger.is-active .line:nth-child(2) {
    opacity: 0;
  }

  .hamburger.is-active .line:nth-child(1) {
    -webkit-transform: translateY(8px) rotate(45deg);
    -ms-transform: translateY(8px) rotate(45deg);
    -o-transform: translateY(8px) rotate(45deg);
    transform: translateY(8px) rotate(45deg);
  }

  .hamburger.is-active .line:nth-child(3) {
    -webkit-transform: translateY(-8px) rotate(-45deg);
    -ms-transform: translateY(-8px) rotate(-45deg);
    -o-transform: translateY(-8px) rotate(-45deg);
    transform: translateY(-8px) rotate(-45deg);
  }

  .navbar_hamburgerContainer {
    display: none;
    gap: 1rem;
    align-items: center;

    @include lg {
      display: flex;
    }
  }
}
