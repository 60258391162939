@import "../../scss/main";

.partyModal {
  width: 700px;
  max-width: calc(100vw - 20px);
  background-color: $BlueBG;
  padding: 2rem;
  border-radius: 10px;
  max-height: calc(100vh - 2rem);
  overflow: auto;

  @include sm {
    height: calc(var(--vh, 1vh) * 100);
    max-height: 100%;
    border-radius: 0;
    width: 100vw;
    max-width: 100%;
    padding: 1rem;
  }

  .partyModal_top {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 1rem;

    @include xs {
      flex-direction: column-reverse;
    }

    h3 {
      color: $HeadingColor;
      font-size: 28px;
      font-weight: 700;
      line-height: 1.2;
      letter-spacing: -0.56px;

      @include sm {
        font-size: 24px;
      }
    }

    img {
      height: 1.5rem;
      cursor: pointer;

      @include xs {
        margin-left: auto;
      }
    }
  }

  p {
    font-size: 18px;
    margin-bottom: 1rem;
    line-height: 1.6;
    color: $TextColor;

    @include sm {
      font-size: 14px;
    }
  }

  small {
    font-size: 14px;
    margin-bottom: 0.5rem;
    line-height: 1.6;
    color: $TextColor;

    @include sm {
      font-size: 12px;
    }
  }

  .partyModal_form {
    h3 {
      color: $HeadingColor;
      font-size: 28px;
      font-weight: 700;
      line-height: 1.2;
      letter-spacing: -0.56px;
      margin-bottom: 0.5rem;

      @include sm {
        font-size: 24px;
      }
    }

    h4 {
      color: $HeadingColor;
      font-size: 22px;
      font-weight: 700;
      line-height: 1.2;
      letter-spacing: -0.56px;
      margin-bottom: 0.25rem;

      @include sm {
        font-size: 18px;
      }

      span {
        font-size: 22px;

        @include sm {
          font-size: 18px;
        }
      }
    }

    .partyModal_formMain {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      column-gap: 1.5rem;
      row-gap: 2rem;
      margin-top: 1rem;
      margin-bottom: 2rem;

      @include sm {
        grid-template-columns: 1fr;
        gap: 1.5rem;
      }

      select,
      input {
        background-color: transparent;
      }
    }

    .partyModal_formDecors {
      display: flex;
      flex-direction: column;
      gap: 4px;
      margin-bottom: 1rem;
      margin-top: -0.5rem;

      label {
        display: flex;
        align-items: flex-start;
        gap: 12px;
        cursor: pointer;
        font-size: 14px;
        font-weight: 400;
        line-height: 1.2;
        color: $TextColor;
        width: fit-content;

        input {
          min-height: 18px;
          min-width: 18px;
          border-radius: 4px;
          border: 2px solid $MutedTextColor;
          margin-top: 2px;
        }
      }
    }

    .partyModal_formActivities {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      column-gap: 2rem;
      margin-bottom: 2rem;
      row-gap: 0.3rem;
      margin-top: 1rem;

      @include md {
        grid-template-columns: 1fr;
      }

      @include sm {
        grid-template-columns: 1fr;
      }

      & > div {
        display: flex;
        flex-direction: column;
        gap: 4px;
      }

      label {
        display: flex;
        align-items: flex-start;
        gap: 12px;
        cursor: pointer;
        font-size: 14px;
        font-weight: 400;
        line-height: 1.2;
        color: $TextColor;
        width: fit-content;

        input {
          min-height: 18px;
          min-width: 18px;
          border-radius: 4px;
          border: 2px solid $MutedTextColor;
          margin-top: 2px;
        }
      }
    }

    & > button {
      height: 50px;
      padding-right: 5rem;
      padding-left: 5rem;
      margin-left: 50%;
      transform: translateX(-50%);
      margin-bottom: 1rem;

      @include sm {
        width: 100%;
        margin-left: 0;
        transform: none;
      }
    }
  }
}

.party_thanksModal {
  width: 700px;
  max-width: calc(100vw - 20px);
  background-color: $BlueBG;
  padding: 2rem;
  border-radius: 10px;
  max-height: calc(100vh - 2rem);
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  @include sm {
    height: calc(var(--vh, 1vh) * 100);
    max-height: 100%;
    border-radius: 0;
    width: 100vw;
    max-width: 100%;
    padding: 1rem;
  }

  .party_thanksModalTop {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-bottom: 2.5rem;

    img {
      height: 1.5rem;
      cursor: pointer;
    }
  }

  .party_thanksModalCongrats {
    height: 230px;
    margin-bottom: 2.5rem;

    @include sm {
      height: 192px;
    }
  }

  h3 {
    color: $HeadingColor;
    font-size: 28px;
    font-weight: 700;
    line-height: 1.2;
    letter-spacing: -0.56px;
    text-align: center;
    margin-bottom: 1rem;

    @include sm {
      font-size: 24px;
    }
  }

  p {
    font-size: 18px;
    line-height: 1.6;
    color: $TextColor;
    text-align: center;
    margin-bottom: 1.9rem;

    span {
      color: $PinkColor;
      cursor: pointer;
    }

    @include sm {
      font-size: 14px;
    }
  }

  & > button {
    margin-bottom: 1.9rem;
  }
}
