@import "../../scss/main";

.ImagePopup {
  width: 700px;
  max-width: calc(100vw - 2rem);
  margin: 0 auto;

  .ImagePopup_picture {
    width: 100%;
    height: auto;
    max-height: calc(var(--vh, 1vh) * 100 - 4rem);
    object-fit: cover;
  }

  .ImagePopup_cross {
   display: flex;
   justify-content: flex-end;
   margin-bottom: 0.5rem;
  }

  svg {
   height: 1.5rem;
   width: 1.5rem;
   margin-left: auto;
   cursor: pointer;
  }
}
