@import "../../scss/main";

.servicesPopup {
  width: 700px;
  max-width: calc(100vw - 2rem);
  background-color: $BlueBG;
  height: 700px;
  max-height: calc(var(--vh, 1vh) * 100 - 2rem);
  padding: 2rem 0;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 1rem;

  @include md {
    padding: 1rem 0;
  }

  @include sm {
    height: calc(var(--vh, 1vh) * 100);
    width: 100vw;
    max-height: calc(var(--vh, 1vh) * 100);
    max-width: 100vw;
    border-radius: 0;
  }

  .servicesPopup_main {
    height: calc(100% - 24px);
    overflow: auto;
    margin-bottom: 1rem;
    padding-bottom: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 2rem;

    ul {
      padding-left: 1rem;
      margin-bottom: 1rem;
    }

    @include md {
      padding: 0 1rem;
    }
  }

  .servicesPopup_cross {
    height: 1.5rem;
    cursor: pointer;
    margin-left: auto;
    margin-bottom: -1.5rem;
    margin-right: 2rem;
    z-index: 2;

    @include md {
      margin-right: 1rem;
    }
  }

  .servicesPopup_pic {
    height: 300px;
    margin-bottom: 1rem;

    @include md {
      height: 205px;
    }
  }

  small {
    font-size: 14px;
    font-weight: 600;
    color: $PinkColor;
    text-transform: uppercase;
    width: 100%;
    text-align: left;
    line-height: 1.4;
  }

  h3 {
    font-size: 28px;
    font-weight: 700;
    color: $HeadingColor;
    margin-bottom: 1rem;
    line-height: 1.2;
    width: 100%;
    text-align: left;

    @include md {
      font-size: 24px;
    }
  }

  p {
    font-size: 18px;
    line-height: 1.6;
    color: $TextColor;
    margin-bottom: 1rem;

    @include md {
      font-size: 16px;
    }
  }

  footer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 2rem;
    gap: 0.5rem;

    @include md {
      padding: 0 1rem;
    }

    @include sm {
      margin-top: auto;
    }

    .servicesPopup_arrowDisable {
      opacity: 0;
      cursor: none;
    }

    .servicesPopup_arrow {
      font-size: 16px;
      font-weight: 600;
      color: $PinkColor;
      display: flex;
      align-items: center;
      gap: 3px;
      cursor: pointer;
      line-height: 1.2;

      &:last-child {
        text-align: right;
      }

      @include md {
        font-size: 14px;
      }

      svg {
        width: 20px;

        path {
          stroke: $PinkColor;
        }
      }
    }
  }
}
