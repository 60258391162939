@import "../../scss/main";

.Button {
  display: inline-block;
  padding: 0.75rem 1.25rem;
  background-color: $PinkColor;
  color: $WhiteColor;
  border-radius: 1.625rem;
  font-size: 1rem;
  font-weight: 600;
  white-space: nowrap;
  transition: all 0.2s ease;

  &:hover {
    background-color: $WhiteColor;
    color: $PinkColor;
  }
}

.Button_white {
  background-color: $WhiteColor;
  color: $PinkColor;

  &:hover {
    background-color: $PinkColor;
    color: $WhiteColor;
  }
}

.Button_outline {
  background-color: transparent;
  color: $MutedTextColor;
  border: 1px solid $GreyColor;
}
