@import "../../scss/main";

.purchasePopup {
  width: 410px;
  max-width: calc(100vw - 2rem);
  margin: 0 auto;
  border-radius: 10px;
  background: $BlueBG;
  padding: 2rem 3rem;
  padding-top: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-height: calc(var(--vh, 1vh) * 100 - 2rem);
  overflow: auto;
  position: relative;


  .purchasePopup_cross {
    height: 1.5rem;
    cursor: pointer;
    position: absolute;
    top: 1rem;
    right: 1rem;
  }
}
