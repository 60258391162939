@import "../../scss/main";

.eventsPopup {
  width: 700px;
  max-width: calc(100vw - 2rem);
  background-color: $BlueBG;
  max-height: calc(var(--vh, 1vh) * 100 - 2rem);
  height: 680px;
  padding: 2rem;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border-radius: 1rem;

  & > div {
    width: 100%;
  }

  .eventsPopup_btn {
    margin-top: 2rem;
    margin-bottom: 1rem;
  }

  @include md {
    padding: 1rem;
    border-radius: 0.5rem;
  }

  @include sm {
    height: calc(var(--vh, 1vh) * 100);
    width: 100vw;
    max-height: calc(var(--vh, 1vh) * 100);
    max-width: 100vw;
    border-radius: 0;
  }

  .eventsPopup_top {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    align-items: center;
    width: 100%;
    margin-bottom: 1rem;
  }

  .eventsPopup_time {
    font-size: 18px;
    font-weight: 500;
    color: $FadeTextColor;
  }

  .eventsPopup_carousel {
    width: calc(100% - 3rem);
    margin-bottom: 1rem;
    width: 100%;

    @include md {
      width: 100%;
    }

    .slick-slide,
    .slick-slide div {
      outline: none !important;
      border: none;
    }
    .slick-prev {
      left: 1rem;
      transform: translate(-100%, -50%);
    }

    .slick-next {
      right: 1rem;
      transform: translate(100%, -50%);
    }

    .slick-arrow {
      height: 2.25rem;
      width: 2.25rem;
      border-radius: 50%;
      background-color: rgba($color: $WhiteColor, $alpha: 0.8);
      display: inline-flex !important;
      justify-content: center;
      align-items: center;
      z-index: 5;

      @include md {
        display: none !important;
      }

      &::before {
        content: "";
      }

      &:hover {
        background-color: rgba($color: $WhiteColor, $alpha: 1);
      }

      svg {
        path {
          stroke: #f06575;
        }
      }
    }

    .slick-disabled {
      cursor: not-allowed;

      &:hover {
        background-color: rgba($color: $WhiteColor, $alpha: 0.8);
      }

      svg {
        path {
          stroke: #667070;
        }
      }
    }
  }

  .eventsPopup_carouselImage {
    width: calc(100% - 8px) !important;
    height: 200px;
    object-fit: cover;
  }

  .eventsPopup_cross {
    height: 1.5rem;
    cursor: pointer;
    margin-left: auto;
  }

  h3 {
    font-size: 28px;
    font-weight: 700;
    color: $HeadingColor;
    margin-bottom: 2rem;
    line-height: 1.2;
    width: 100%;
    text-align: left;

    @include md {
      font-size: 24px;
    }
  }

  .eventsPopup_soldIcon {
    font-size: 14px;
    font-weight: 600;
    color: $RedColor;
    line-height: 1.2;
    border: 1px solid $RedColor;
    padding: 2px 2px;
    border-radius: 4px;
  }
  .eventsPopup_subDetailsContainer {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 0.5rem;

    @include sm {
      grid-template-columns: 1fr;
    }
  }

  .eventsPopup_subDetails {
    display: flex;
    gap: 5px;
    margin-bottom: 1rem;

    h4 {
      font-size: 20px;
      font-weight: 700;
      color: $HeadingColor;
      line-height: 1;
      margin: 0;
      line-height: 1.1;

      @include md {
        font-size: 18px;
      }
    }

    p {
      margin: 0;
      line-height: 1.1;
    }
  }

  p {
    font-size: 18px;
    line-height: 1.6;
    color: $TextColor;
    margin-bottom: 1rem;
    white-space: pre-line;

    @include md {
      font-size: 16px;
    }
  }

  footer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include sm {
      margin-top: auto;
    }

    .eventsPopup_arrowDisable {
      opacity: 0;
      cursor: none;
    }

    .eventsPopup_arrow {
      font-size: 16px;
      font-weight: 600;
      color: $PinkColor;
      display: flex;
      align-items: center;
      gap: 3px;
      cursor: pointer;

      @include md {
        font-size: 14px;
      }

      svg {
        width: 20px;

        path {
          stroke: $PinkColor;
        }
      }
    }
  }
}
