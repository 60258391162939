@import "../../scss/main";

.datePicker {
  display: flex;
  flex-direction: column;
  position: relative;
  border-bottom: 1px solid $HeadingColor;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

  label {
   font-size: 14px;
   color: $TextColor;
  }

  .datePicker_icon {
    height: 20px;
    width: 20px;
    position: absolute;
    bottom: 0.5rem;
    right: 0;
    z-index: 1;
  }

  .react-datepicker-wrapper {
    height: 40px;

    div {
      height: 100%;
    }
  }

  .react-datepicker__view-calendar-icon input {
    padding: 0;
  }

  input {
    border: none;
    font-size: 14px;
    outline: none;
    height: 100%;
    width: 100%;
    padding: 0;

    &::placeholder {
      color: $MutedTextColor;
    }
  }
}
