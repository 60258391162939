@import "../../scss/main";

.servicesSec {
  background-color: $BlueColor;
  padding-bottom: 4rem;
  position: relative;

  @include sm {
    padding-bottom: 0;
  }

  .servicesSec_images {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  .servicesSec_blue1 {
    position: absolute;
    top: 20rem;
    left: 0;
    width: 20vw;
    min-width: 150px;

    @include sm {
      top: 12rem;
    }
  }

  .servicesSec_blue2 {
    position: absolute;
    bottom: 25rem;
    left: 10vw;
    width: 20vw;
    min-width: 150px;

    @media (max-width: 1440px) {
      left: 2rem;
    }

    @include md {
      bottom: none;
      top: 28rem;
      left: 0;
      transform: translateX(-40%);
    }
  }

  .servicesSec_blue3 {
    position: absolute;
    bottom: 8rem;
    right: 0;
    width: 20vw;
    min-width: 150px;

    @include md {
      display: none;
    }
  }

  .servicesSec_blueLine {
    position: absolute;
    bottom: 14rem;
    right: 0;
    width: 20vw;
    min-width: 150px;

    @include md {
      display: none;
    }
  }

  .servicesSec_titleContainer {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
    width: 1215px;
    max-width: calc(100vw - 2rem);
    margin: 0 auto;
    margin-top: -6rem;
    margin-bottom: 4rem;
    position: relative;
    grid-template-columns: 1fr;
    gap: 4px;
    margin-bottom: 1.5rem;
    margin-top: -5rem;

    // @include md {
    //   grid-template-columns: 1fr;
    //   gap: 4px;
    //   margin-bottom: 1.5rem;
    //   margin-top: -5rem;
    // }

    small {
      font-size: 14px;
      font-weight: 600;
      color: $TextColor;
      display: none;
    }

    h3 {
      font-size: 44px;
      font-weight: 700;
      color: $HeadingColor;
      letter-spacing: -0.88px;
      line-height: 1.1;

      @include md {
        font-size: 32px;
        margin-top: 1rem;
      }
    }

    p {
      color: $TextColor;
      line-height: 1.6;
      font-size: 1rem;
      // padding-top: 3.75rem;
      margin: 1.5rem 0;

      @include md {
        padding-top: 0;
        font-size: 14px;
        margin: 1rem 0;
      }
    }
  }

  .servicesSec_services {
    position: relative;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1rem;
    width: 1215px;
    max-width: calc(100vw - 2rem);
    margin: 0 auto;

    @include lg {
      grid-template-columns: repeat(3, 1fr);
    }

    @include md {
      grid-template-columns: repeat(2, 1fr);
    }

    @include sm {
      display: none;
    }
  }

  .servicesSec_service {
    padding: 20px;
    background-color: $WhiteColor;
    border-radius: 10px;
    border: 1px solid #eee;
    box-shadow: 8px 8px 0px 0px rgba(0, 0, 0, 0.08);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;

    img {
      width: 100%;
      height: 167px;
      object-fit: contain;
      margin-bottom: 1rem;
    }

    h3 {
      font-size: 18px;
      font-weight: 700;
      color: $TextColor2;
      margin-bottom: 4px;
      line-height: 1.2;
    }

    p {
      font-size: 14px;
      color: $FadeTextColor;
      line-height: 1.6;
      margin-bottom: 1rem;
    }

    .servicesSec_serviceArrow {
      height: 1.5rem;
      width: 50%;
      cursor: pointer;
      margin-bottom: 0;
    }
  }
}

.servicesSec_signUpCard {
  background-color: $PinkColor;
  border-radius: 10px;
  box-shadow: 8px 8px 0px 0px rgba(0, 0, 0, 0.08);
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;

  @include sm {
    margin-top: 2rem;
    border-radius: 0;
    width: 100vw;
    margin-left: -1rem;
    box-shadow: none;
  }

  button {
    border: 1px solid $WhiteColor;
  }

  h3 {
    font-size: 28px;
    font-weight: 700;
    color: $WhiteColor;
    line-height: 1.2;
    margin-bottom: 1.5rem;

    @include sm {
      text-align: center;
    }
  }

  input {
    height: 2.5rem;
    width: 100%;
    outline: none;
    border: none;
    border-bottom: 1px solid $WhiteColor;
    background-color: transparent;
    margin-bottom: 1rem;
    font-size: 14px;
    line-height: 1.6;
    color: $WhiteColor;

    &::placeholder {
      color: rgba(255, 255, 255, 0.6);
    }
  }

  button {
    width: 100%;
  }
}

.mobileServicesCarousel {
  max-width: calc(100vw - 2rem);
  margin: 0 auto;
  display: none;

  @include sm {
    display: block;
  }

  .slick-dots {
    z-index: 1;
  }

  .slick-slide,
  .slick-slide div {
    outline: none !important;
    border: none;
  }

  .servicesSec_service {
    width: calc(100% - 0.5rem) !important;
    height: 100%;
    display: flex !important;
  }

  .slick-track {
    display: flex !important;
  }

  .slick-slide {
    height: inherit !important;

    & > div {
      height: 100% !important;
    }
  }

  .slick-dots {
    li {
      width: 15px;
    }
  }
}

.servicesSec_tags {
  flex-wrap: wrap;
  gap: 12px;
  max-width: calc(100vw - 2rem);
  margin: 0 auto;
  margin-bottom: 1.5rem;
  position: relative;
  display: none;

  @include sm {
    display: flex;
  }

  span {
    display: inline-block;
    padding: 6px 1rem;
    border-radius: 1.5rem;
    border: 1px solid $TextColor;
    font-size: 14px;
    font-weight: 500;
    color: $TextColor;
    line-height: 1;
  }

  .servicesSec_tagActive {
    background-color: $WhiteColor;
    color: $PinkColor;
    border: 1px solid $WhiteColor;
  }
}
