@import "../../scss/main";

.eventsSec {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 3rem;
  position: relative;

  @include md {
    padding-bottom: 28px;
  }

  .eventsSec_blue {
    position: absolute;
    top: 0;
    left: 0;
    width: 22vw;
    left: 5vw;
    transform: translateY(-6rem);
    min-height: 150px;

    @include xl {
      transform: translateY(-2rem);
    }

    @include md {
      display: none;
    }
  }

  .eventsSec_white {
    position: absolute;
    top: 5vw;
    right: 0;
    width: 31vw;
    min-width: 150px;

    @include md {
      top: 5%;
      width: 70vw;
      height: calc(var(--vh, 1vh) * 80);
    }
  }

  .eventsSec_blueDots {
    position: absolute;
    top: 38%;
    right: 3vw;
    width: 13vw;
    min-width: 100px;

    @media (max-width: 1500px) {
      right: 0rem;
    }

    @include xxl {
      top: 10%;
      right: 10vw;
    }

    @include sm {
      top: 18%;
      right: 10vw;
    }
  }

  .eventsSec_collage {
    height: 280px;
    transform: translateY(-30%);
    position: relative;

    @include md {
      height: 220px;
      max-width: 90vw;
      transform: none;
      margin-top: 1.9rem;
    }
  }

  small {
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    color: $PinkColor;
    margin-top: -3rem;
    margin-bottom: 1rem;
    position: relative;

    @include md {
      margin-top: 1rem;
    }
  }

  h3 {
    font-size: 44px;
    font-weight: 700;
    color: $HeadingColor;
    line-height: 1.1;
    margin-bottom: 0.5rem;
    text-align: center;
    position: relative;

    @include md {
      font-size: 32px;
    }
  }

  .eventsSec_subText {
    max-width: 767px;
    width: calc(100% - 20px);
    margin: 0 auto;
    text-align: center;
    margin-bottom: 2rem;

    @include md {
      margin-bottom: 1.5rem;
    }
  }

  .eventsSec_formHeader {
    display: none;
    justify-content: space-between;
    width: calc(100vw - 2rem);
    margin: 0 auto;
    margin-bottom: 1.5rem;
    position: relative;

    @include md {
      display: flex;
    }

    .eventsSec_formHeaderFilter {
      border-radius: 31px;
      border: 1px solid #e5e5e5;
      background: $WhiteColor;
      padding: 8px 16px;
      font-size: 16px;
      font-weight: 500;
      color: $TextColor;
      display: inline-flex;
      align-items: center;
      gap: 6px;

      svg {
        height: 20px;
      }
    }
  }

  .eventsSec_formHeaderFilterOpen {
    color: $PinkColor !important;

    svg {
      path {
        stroke: $PinkColor;
      }
    }
  }

  .eventsSec_formOpen {
    @include md {
      display: flex !important;
    }
  }

  .eventsSec_form {
    position: relative;
    display: flex;
    width: 1216px;
    max-width: calc(100vw - 2rem);
    margin: 0 auto;
    border-radius: 10px;
    background-color: $WhiteColor;
    margin-bottom: 1rem;
    padding: 1.5rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    button[type="submit"] {
      border: 1px solid $PinkColor;
    }

    .eventsSec_formToggler {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      justify-content: center;
      width: auto;

      @include md {
        justify-content: flex-start;
      }

      span {
        cursor: pointer;
      }
    }

    .eventsSec_headerSize {
      display: none;

      @include md {
        display: block;
      }
    }

    @include md {
      gap: 1.25rem;
      display: none;
    }

    & > div {
      width: 25%;

      @include xl {
        width: 33%;
      }

      @include lg {
        width: 50%;
      }

      @include md {
        width: 100%;
      }
    }

    .eventsSec_formDropdownContainer {
      display: flex;
      gap: 1rem;
      width: 30%;

      @include xl {
        width: 40%;
      }

      @include lg {
        width: 60%;
      }

      @include md {
        width: 100%;
      }

      @include sm {
        flex-direction: column;
      }
    }

    .eventsSec_formDropdown {
      display: flex;
      flex-direction: column;
      width: 50%;

      @include sm {
        width: 100%;
      }
      label {
        margin-bottom: 0.25rem;
      }

      select {
        height: 2.5rem;
        border-radius: 6px;
        border: 1px solid #e5e5e5;
        background: $WhiteColor;
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        outline: none;
        padding: 0 12px;
        color: $MutedTextColor;
        min-width: 150px;

        &:disabled {
          opacity: 0.7;
          cursor: not-allowed;
        }
      }
    }

    .eventsSec_search {
      position: relative;
      display: flex;
      align-items: flex-end;

      @include lg {
        margin-top: 1rem;
        margin-left: auto;
        margin-right: auto;
      }

      @include md {
        margin-top: 0;
      }

      input {
        height: 2.5rem;
        width: 100%;
        border: none;
        outline: none;
        border-bottom: 1px solid $HeadingColor;
        padding-right: 1.5rem;

        &::placeholder {
          color: $MutedTextColor;
        }
      }

      img {
        position: absolute;
        bottom: 0.5rem;
        right: 0;
        height: 20px;
      }
    }

    .eventsSec_formBtns {
      display: flex;
      gap: 1rem;
      align-items: center;
      justify-content: flex-end;
      width: auto;

      @include xl {
        width: 100%;
        justify-content: center;
        margin-top: 1rem;
      }

      button {
        height: fit-content;

        @include sm {
          width: 50%;
        }
      }
    }
  }

  .eventsSec_header {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 1215px;
    max-width: calc(100vw - 2rem);
    margin: 0 auto;
    margin-bottom: 1rem;

    @include md {
      display: none;
    }
  }

  .eventsSec_headerSize {
    font-size: 14px;
    color: $TextColor;
    select {
      height: 2.5rem;
      border-radius: 6px;
      border: 1px solid #e5e5e5;
      background: $WhiteColor;
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
      outline: none;
      padding: 0 12px;
      color: $MutedTextColor;
      margin: 0 0.5rem;
    }
  }

  .eventsSec_main {
    position: relative;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1rem;
    width: 1215px;
    max-width: calc(100vw - 2rem);
    margin: 0 auto;

    @include xl {
      grid-template-columns: repeat(3, 1fr);
    }

    @include md {
      grid-template-columns: repeat(2, 1fr);
      gap: 0.5rem;
    }

    .eventsSec_eventBox {
      display: flex;
      flex-direction: column;
      border-radius: 10px;
      border: 1px solid #eee;
      background: $WhiteColor;
      box-shadow: 8px 8px 0px 0px rgba(0, 0, 0, 0.08);
      padding: 20px;
      cursor: pointer;
      justify-content: space-between;
      position: relative;

      @include lg {
        padding: 10px;
        border-radius: 4px;
      }

      @include md {
        box-shadow: 4.590126991271973px 4.590126991271973px 0px 0px
          rgba(0, 0, 0, 0.08);
      }

      .eventsSec_eventBoxSold {
        position: absolute;
        right: 1.6rem;
        top: 1.6rem;
        font-size: 14px;
        font-weight: 600;
        color: $RedColor;
        line-height: 1.2;
        border: 1px solid $RedColor;
        padding: 2px 2px;
        border-radius: 4px;

        @include lg {
          top: 1rem;
          right: 1rem;
        }
      }

      .eventsSec_eventBoxPic {
        width: 100%;
        height: 167px;
        object-fit: cover;
        border-radius: 6px;
        margin-bottom: 1rem;

        @include lg {
          border-radius: 2px;
          margin-bottom: 0.5rem;
        }

        @include md {
          height: auto;
          aspect-ratio: 4/3;
        }
      }

      h3 {
        font-size: 18px;
        font-weight: 700;
        line-height: 1.2;
        text-align: left;
        margin-bottom: 0.5rem;
        display: -webkit-box;
        max-width: 100%;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;

        @include lg {
          font-size: 14px;
          font-weight: 600;
          line-height: 1.6;
          margin-bottom: 0.5rem;
        }
      }

      span {
        font-size: 16px;
        font-weight: 500;
        color: $FadeTextColor;
        margin-bottom: 2px;
        line-height: 1.1;

        @include lg {
          font-size: 12px;
        }
      }

      p {
        font-size: 14px;
        color: $TextColor;
        display: -webkit-box;
        max-width: 100%;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        line-break: anywhere;

        @include lg {
          font-size: 12px;
          line-height: 1.6;
        }
      }

      .eventsSec_eventBoxArrow {
        height: 1.5rem;
        width: 40%;
        cursor: pointer;

        @include sm {
          display: none;
        }
      }
    }
  }

  .eventsSec_arrows {
    font-size: 14px;
    color: $TextColor;
    line-height: 1.6;
    display: inline-flex;
    align-items: center;

    img {
      height: 20px;
      cursor: pointer;

      &:first-child {
        margin-left: 20px;
        margin-right: 24px;
      }
    }
  }

  .eventsSec_pagination {
    display: flex;
    gap: 8px;
    margin-top: 1rem;
    align-items: center;

    span {
      height: 36px;
      width: 36px;
      font-size: 14px;
      line-height: 1.6;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      color: $SecondaryTextColor;
      cursor: pointer;
    }

    .eventsSec_paginationActive {
      background-color: $WhiteColor;
      border-radius: 50%;
      color: $PinkColor;
    }

    img {
      height: 20px;
      width: 20px;
      cursor: pointer;
    }
  }
  .eventsSec_headingPadding{
    padding-bottom: 32px;

  @include md {
    padding-bottom: 20px;
  }
  }
}
