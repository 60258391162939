@import "../../scss/main";

.textarea {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;

  span {
    position: absolute;
    bottom: 0.75rem;
    right: 0;
    font-size: 12px;
    line-height: 1.6;
    color: $TextColor;
  }

  label {
    font-size: 14px;
    font-weight: 500;
    color: $TextColor;
  }

  input {
    height: 44px ;
    max-height: 44px;
    max-width: 100%;

    border: none;
    outline: none;
    border-bottom: 1px solid $HeadingColor;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    background-color: transparent;
    font-size: 14px;
    color: $TextColor;
    font-family: "Poppins";
    padding-right: 3rem;

    &::placeholder {
      color: $MutedTextColor;
    }
  }

 
}
