@import "../../scss/main";

.donationSec {
  padding: 4rem 0;
  background-color: $BlueColor;

  @include md {
    padding: 2.25rem 0;
  }

  .container {
    width: 1215px;
    max-width: calc(100vw - 2rem);
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include md {
      flex-direction: column;
    }
  }

  .donationSec_content {
    width: 70%;

    @include md {
      text-align: center;
      width: 100%;
      margin-bottom: 1.9rem;
    }
  }

  h3 {
    font-size: 44px;
    font-weight: 700;
    color: $HeadingColor;
    margin-bottom: 1rem;
    line-height: 1.1;

    @include md {
      font-size: 32px;
    }
  }

  p {
    font-size: 18px;
    color: $TextColor;
    line-height: 1.6;

    @include md {
      font-size: 16px;
    }
  }

  button {
    @include xs {
      width: 100%;
    }
  }

  .donationSec_btnContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  img {
    height: 109px;

    @include lg {
      height: 80px;
    }

    @include md {
      margin-bottom: 1.5rem;
      height: 109px;
    }

    @include xs {
      width: 100%;
      height: auto;
    }
  }
}
