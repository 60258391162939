@import "../../scss/main";

.bookTour {
  width: 700px;

  // height: 700px;
  max-width: calc(100vw - 2rem);
  margin: 0 auto;
  border-radius: 10px;
  background: $BlueBG;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-height: calc(var(--vh, 1vh) * 100 - 2rem);
  overflow: auto;

  @include sm {
    // height: calc(var(--vh, 1vh) * 100);
    // width: 100vw;
    // max-height: calc(var(--vh, 1vh) * 100);
    // max-width: 100vw;
    // border-radius: 0;
    padding: 1.5rem 1.5rem;

  }

  .bookTourDetail {
    display: flex;
    gap: 0.5rem;
    text-align: left;
    width: 100%;
    font-size: 18px;
    line-height: 1.6;
    color: $TextColor;
    align-items: center;
    margin-bottom: 1rem;

    @include md {
      font-size: 14px;
      line-height: 1.4;
    }

    img {
      height: 1.25rem;
    }
  }

  button {
    margin-top: 2rem;
  }

  .bookTour_cross {
    height: 1.5rem;
    cursor: pointer;
    margin-left: auto;
    transform: translate(1rem, -1rem);

    @include sm {
    transform: translate(0.5rem, -0.5rem);

    }
  }

  h3 {
    font-size: 24px;
    font-weight: 700;
    line-height: 1.2;
    color: $HeadingColor;
    margin-bottom: 1rem;
    text-align: center;

    @include md {
      font-size: 20px;
    }

    @include sm {
      font-size: 18px;
    }
  }

  p {
    font-size: 18px;
    line-height: 1.6;
    color: $TextColor;
    margin-bottom: 2rem;

    @include md {
      font-size: 14px;
      margin-bottom: 1.75rem;
    }
  }

  .bookTour_form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 2rem;

    @include md {
      gap: 1.5rem;
    }

    & > div {
      width: 100%;
    }

    button {
      margin-top: 2rem;

      @include md {
        margin-top: 0.5rem;
      }
    }

    .bookTour_formInputs {
      display: flex;
      gap: 1.5rem;

      @include sm {
        flex-direction: column;
      }
    }
  }

  .bookTour_congratsImage {
    width: 311px;
    margin-bottom: 2.5rem;

    @include sm {
      width: 260px;
      margin-bottom: 1.5rem;
    }
  }

  .bookTour_submission {
    display: flex;
    flex-direction: column;
    align-items: center;

    p {
      margin-bottom: 2rem;
    }

    button {
      margin-bottom: 2rem;
    }

    .bookTour_subMsg {
      font-size: 14px;
      line-height: 1.6;
      color: $TextColor;

      span {
        color: $PinkColor;
        font-weight: 500;
        cursor: pointer;
      }
    }
  }
}
