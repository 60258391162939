@import "../../scss/main";

.toggler {
  input {
    display: none;
  }
  label {
    position: relative;
    cursor: pointer;
    display: block;
    width: 50px;
    height: 30px;
    background: #cccccc;
    border-radius: 50px;
    transition: 300ms linear;
  }

  label:before {
    position: absolute;
    top: 50%;
    left: 2px;
    transform: translateY(-50%);
    content: "";
    display: block;
    width: 25px;
    height: 25px;
    background: #ffffff;
    border-radius: 50%;
    transition: left 100ms linear;
  }

  input:checked + label {
    background: $PinkColor;
  }

  input:checked + label:before {
    left: 23px;
  }
}
