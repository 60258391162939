@import "../../scss/main";

.footer {
  padding: 3rem 0;
  position: relative;

  .container {
    position: relative;
    width: 1215px;
    max-width: calc(100vw - 2rem);
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    @include lg {
      grid-template-columns: 1fr;
      gap: 2rem;
    }
  }

  .footer_blue {
    position: absolute;
    top: 0;
    left: 0;
    width: 17vw;
    min-height: 150px;
    transform: translateY(-40%);

    @include md {
      display: none;
    }
  }

  .footer_white {
    position: absolute;
    bottom: 0%;
    right: 5%;
    width: 17vw;
    min-width: 150px;
  }

  .footer_dots {
    position: absolute;
    bottom: 10%;
    right: 2%;
    width: 13vw;
    min-width: 100px;
  }

  .footer_formContainer {
    display: flex;
    flex-direction: column;

    .footer_icon {
      height: 20px;
      margin-bottom: 1rem;
      width: fit-content;
      cursor: pointer;
    }

    a {
      font-size: 20px;
      font-weight: 500;
      color: $PinkColor;
      margin-bottom: 3px;
      text-decoration: none;

      @include md {
        font-size: 18px;
      }
    }

    p {
      font-size: 16px;
      font-weight: 500;
      line-height: 1.6;
      margin-bottom: 1rem;
      color: $TextColor;
    }

    form {
      display: flex;
      gap: 1rem;

      @include sm {
        width: 100%;
        flex-direction: column;
      }

      .textInput {
        width: fit-content;

        @include sm {
          width: 100%;
        }
      }

      input {
        width: 310px;
        max-width: 100%;

        @include sm {
          width: 100%;
        }
      }
    }
  }

  .footer_mediaContainer {
    display: flex;
    flex-direction: column;
    align-items: center;

    h3 {
      font-size: 24px;
      font-weight: 700;
      line-height: 1.2;
      color: $HeadingColor;
      text-align: center;
      margin-bottom: 1.5rem;

      @include lg {
        width: 100%;
        text-align: left;
      }
    }
  }

  .footer_media {
    height: 353px;
    width: 474px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;

    @include sm {
      height: 255px;
      width: 343px;
      max-width: 90vw;
    }

    .footer_mediaPath1 {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 42%;
    }

    .footer_mediaPath2 {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 55.5%;
    }

    .footer_mediaPath3 {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 74.5%;
    }

    .footer_mediaFb {
      position: absolute;
      top: 53%;
      left: 49%;
      transform: translate(-50%, -50%);
      width: 41%;
      cursor: pointer;
      z-index: 2;

      img {
        position: relative;
        height: 100%;
        width: 100%;
      }
    }

    .footer_mediaInstagram {
      position: absolute;
      top: -5%;
      right: 25%;
      width: 22%;

      cursor: pointer;
      z-index: 2;

      img {
        position: relative;
        height: 100%;
        width: 100%;
      }
    }

    .footer_mediaTiktok {
      position: absolute;
      bottom: 20%;
      left: 5%;
      width: 25.5%;

      cursor: pointer;
      z-index: 2;

      img {
        position: relative;
        height: 100%;
        width: 100%;
      }
    }

    .footer_mediaPeople {
      position: absolute;
      bottom: 0%;
      right: 8%;
      width: 33%;

      cursor: pointer;
      z-index: 2;

      img {
        position: relative;
        height: 100%;
        width: 100%;
      }
    }

    .footer_mediaYellow {
      position: absolute;
      top: 10%;
      left: 20%;
      width: 12%;
    }

    .footer_mediaMustard {
      position: absolute;
      top: 31%;
      right: 16%;
      width: 12%;
    }

    .footer_mediaBlue {
      position: absolute;
      width: 44%;
      top: 55%;
      left: 55%;
      transform: translate(-50%, -50%);
    }
  }

  .footer_bottom {
    width: 1215px;
    max-width: calc(100vw - 2rem);
    margin: 0 auto;
    font-size: 14px;
    color: $FadeTextColor;
    line-height: 1.6;
    position: relative;

    a {
      color: inherit;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}
