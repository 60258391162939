@import "../../scss/main";
// 1vw = 14.4px
.welcomeSec {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding-bottom: 17vw;
  padding-top: 112px;

  @include xl {
    padding-bottom: 220px;
  }

  @include md {
    padding-bottom: 131px;
  }

  .welcomeSec_blue1 {
    position: absolute;
    top: 0;
    left: 0;
    width: 28.3vw;
    min-width: 200px;

    @include md {
      min-width: 150px;
    }
  }

  .welcomeSec_yellow {
    position: absolute;
    top: 0;
    right: 0;
    width: 28.3vw;
    min-width: 200px;

    @include md {
      min-width: 100px;
    }
  }

  .welcomeSec_yellow2 {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 28.3vw;
    min-width: 200px;
    transform: translateY(30%);

    @include md {
      display: none;
    }
  }

  .welcomeSec_blue2 {
    position: absolute;
    bottom: 3vw;
    right: 0;
    width: 15vw;

    @include md {
      display: none;
    }
  }

  .welcomeSec_white1 {
    position: absolute;
    top: 16.67vw;
    left: 0;
    width: 12vw;

    @include md {
      width: 15vw;

      min-width: 100px;
      top: 40rem;
    }
  }

  .welcomeSec_yellowLines {
    position: absolute;
    top: 24.67vw;
    left: 0;
    width: 25vw;

    @include md {
      width: 35vw;
      min-width: 180px;
      top: 50rem;
    }
  }

  .welcomeSec_imagesContainer {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
  .welcomeSec_purple {
    position: absolute;
    top: 16.5vw;
    right: 6vw;
    width: 15.7vw;
    min-width: 50px;

    @include md {
      min-width: 128px;
      right: 0;
      transform: translateX(70%);
      top: 18vw;
    }

    @include sm {
      top: 6vw;
    }
  }

  .welcomeSec_whiteImage2 {
    position: absolute;
    top: 54.8vw;
    right: 5vw;
    width: 18.8vw;
    min-width: 50px;

    @include md {
      min-width: 130px;
      right: 0;
      transform: translateX(30%);
      top: 60rem;
    }
  }

  .welcomeSec_blueDots {
    position: absolute;
    top: 45.8vw;
    right: 4.5vw;
    width: 13.2vw;
    min-width: 50px;

    @include md {
      min-width: 120px;
      right: 0;
      transform: translateX(50%);
      top: 52rem;
    }
  }

  .welcomeSec_image {
    width: 550px;
    max-width: 70vw;
    margin-bottom: 4rem;

    @include md {
      margin-bottom: 2rem;
    }
  }

  small {
    font-size: 14px;
    color: $PinkColor;
    font-weight: 600;
    text-transform: uppercase;
  }

  .welcomeSec_content {
    width: 652px;
    max-width: 100vw;
    padding: 0 1rem;
    text-align: center;
    position: relative;

    h1 {
      font-size: 32px;
      font-weight: 700;
      color: $HeadingColor;
      line-height: 1.1;
      margin-bottom: 1.5rem;

      @include md {
        font-size: 30px;
      }

    }

    p {
      font-size: 18px;
      line-height: 1.6;
      color: $TextColor;
      margin-bottom: 4.5rem;

      @include md {
        font-size: 16px;
        margin-bottom: 2.3rem;
      }
    }
  }

  .welcomeSec_scrollArrow {
    height: 1.75rem;
  }

  .welcomeSec_detailsContainer {
    position: relative;
    width: 1248px;
    max-width: 100vw;
    padding: 0 1rem;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 2rem;
    margin-top: 4rem;

    @include md {
      grid-template-columns: 1fr;
      margin-top: 2.75rem;
    }
  }

  .welcomeSec_details {
    h3 {
      font-size: 2.25rem;
      font-weight: 700;
      color: $HeadingColor;
      line-height: 1.1;
      margin-top: 1rem;
      margin-bottom: 0.25rem;

      @include md {
        font-size: 2rem;
        margin-bottom: 1rem;
        margin-top: 4px;
      }
    }

    p {
      font-size: 1rem;
      color: $TextColor;
      margin-bottom: 1rem;

      strong {
        font-size: 600;
      }

      @include md {
        font-size: 14px;
      }
    }

    h4 {
      font-size: 24px;
      font-weight: 700;
      color: $HeadingColor;
      line-height: 1.1;
      padding-top: 1rem;
      margin-bottom: 1rem;
    }

    button {
      margin-top: 1rem;

      @include sm {
        width: 100%;
      }
    }

    .welcomeSec_detailPoint {
      display: flex;
      gap: 1rem;
      align-items: flex-start;
      margin-top: 2rem;

      @include md {
        margin-top: 1rem;
      }

      h4 {
        padding-top: 0;
        font-size: 20px;
        margin-bottom: 12px;

        @include md {
          font-size: 18px;
          margin-bottom: 4px;
        }
      }

      p {
        margin-bottom: 0;
      }

      .welcomeSec_detailPointNumber {
        height: 2.25rem;
        width: 2.25rem;
        min-height: 2.25rem;
        min-width: 2.25rem;
        background-color: $WhiteColor;
        border-radius: 50%;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        color: $DarkBlueColor;
        font-size: 14px;
        font-weight: 700;
      }
    }
  }
}
